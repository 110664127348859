import { createRoot } from "react-dom/client";
import { Buffer } from "buffer";
import { App } from "./App";

import "./../index.css";

// The window.Buffer is required for the Solana adapter
window.Buffer = Buffer;

const root = createRoot(document.getElementById("top")!);
root.render(<App />);
