import React, { useMemo } from "react";

import Spinner from "../../../assets/icons/loading-spinner.svg";
import Icon from "../icon/Icon";
import { BASE_BUTTON_CLASSES } from "../../../styles/commonClasses";
import "./style.css";
import Tooltip from "../tooltip/Tooltip";
import { twMerge } from "tailwind-merge";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  icon?: JSX.Element;
  className?: string;
  animate?: boolean;
  disabledReason?: string;
  toolTipPlacement?: "right" | "left" | "top" | "bottom";
  classes?: {
    children?: string;
    wrapper?: string;
  };
}

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "outlined"
  | "gray"
  | "secondary-gray"
  | "regular"
  | "tooltip";
export type ButtonSize = "lg" | "md" | "sm" | "xs" | "xxs";

export const Button = ({
  variant = "secondary",
  size = "md",
  icon,
  isLoading,
  children,
  disabled,
  className,
  animate,
  disabledReason,
  classes,
  toolTipPlacement = "top",
  onClick,
  ...props
}: ButtonProps) => {
  const getSizeClasses = () => {
    switch (size) {
      case "xs": {
        return "px-3 h-[27px] text-sm rounded-md after:rounded-md";
      }
      case "sm": {
        return "px-4 h-[36px] text-sm rounded after:rounded";
      }
      case "md": {
        return "px-4 h-[42px] rounded-md after:rounded-md";
      }
      case "xxs": {
        return "px-1 py-1 rounded after:rounded";
      }
      case "lg": {
        return "px-4 h-[56px] rounded-lg after:rounded-lg";
      }
      default: {
        return "px-4 h-[42px] rounded-md after:rounded-md";
      }
    }
  };

  const borderClass = `after:content-[''] after:block after:absolute after:w-full after:left-0 after:top-0 after:h-full after:border-2 `;

  const getModeClasses = () => {
    switch (variant) {
      case "primary": {
        return `
          ${borderClass} after:opacity-0 text-gray-50 bg-brand-mixed-gradient
          ${size === "lg" ? "shadow-brand-purple-sm" : ""} 
          hover:after:opacity-100 hover:after:border-brand-purple-light
          ${size === "lg" ? "hover:shadow-brand-purple-lg" : ""}
          active:bg-none active:bg-brand-purple-dark-1 active:shadow-none active:after:opacity-0
          disabled:shadow-none 
          ${isLoading ? "disabled:text-gray-50" : "disabled:text-gray-50-alpha/50"}
          disabled:after:opacity-0 disabled:active:bg-brand-mixed-gradient
        `;
      }
      case "outlined": {
        return `
          ${borderClass} after:border-gray-50 bg-transparent text-gray-50 
          hover:after:opacity-0 hover:bg-gray-50 hover:text-gray-950
          active:bg-brand-purple-dark-1 disabled:bg-gray-500 active:text-gray-50 
          disabled:after:opacity-100 
          ${isLoading ? "disabled:text-gray-50" : "disabled:text-gray-500"}
          ${isLoading ? "disabled:after:border-gray-50" : "disabled:after:border-gray-500"} 
          disabled:bg-transparent
        `;
      }
      case "secondary": {
        return `
          text-gray-950 bg-gray-50 
          hover:bg-brand-purple-light hover:text-gray-50 
          active:bg-brand-purple-dark-1 active:text-gray-50
          disabled:bg-gray-700 disabled:text-gray-400
          ${isLoading ? "disabled:bg-gray-50 disabled:text-gray-950" : "disabled:bg-gray-500"}
        `;
      }
      case "secondary-gray": {
        return `
          text-gray-50 bg-gray-700 hover:bg-gray-500 active:bg-gray-700 font-normal
          disabled:text-gray-400 
          ${
            isLoading
              ? "disabled:text-gray-50 disabled:hover:bg-gray-400"
              : "disabled:text-gray-300 disabled:hover:bg-gray-700"
          } 
        `;
      }
      case "regular": {
        return `
          text-gray-50 bg-brand-purple-light font-normal 
          ${isLoading ? "disabled:text-gray-50 " : "disabled:text-gray-300 "}
        `;
      }
      case "tooltip": {
        return `
          text-gray-50 bg-gray-500 font-normal hover:bg-gray-400 active:bg-gray-600 
          disabled:bg-gray-500 disabled:hover:bg-gray-500 disabled:opacity-50
        `;
      }
      default: {
        return `
          text-gray-50 bg-gray-600 hover:bg-gray-400 active:bg-gray-700 
          ${isLoading ? "disabled:text-gray-50 " : "disabled:text-gray-300 "} 
          ${isLoading ? "disabled:hover:bg-gray-400" : "disabled:hover:bg-gray-600"}
        `;
      }
    }
  };

  const computedClasses = useMemo(() => {
    const modeClass = getModeClasses();
    const sizeClass = getSizeClasses();
    return [modeClass, sizeClass].join(" ");
  }, [variant, size, isLoading]);

  return (
    <button
      disabled={disabled || isLoading}
      type="button"
      className={twMerge(
        `
        ${BASE_BUTTON_CLASSES} 
        ${computedClasses} items-center justify-center 
        ${animate && "animate"}`,
        className,
        classes?.wrapper,
      )}
      onClick={(e) => {
        if (e.type === "click") {
          e.stopPropagation();
        }
        onClick?.(e);
      }}
      {...props}
    >
      <Tooltip
        offset={[0, 20]}
        content={disabled && disabledReason}
        className="flex"
        placement={toolTipPlacement}
      >
        <div className={`flex items-center justify-center ${size === "xs" ? "gap-1.5" : "gap-2"}`}>
          {(isLoading || icon) &&
            (isLoading ? (
              <Icon inheritStroke={true} inheritFill={false} size="md" icon={<Spinner />} />
            ) : (
              <div>{icon}</div>
            ))}
          {children ? (
            <div className={classes?.children}>{isLoading ? "Loading..." : children}</div>
          ) : null}
        </div>
      </Tooltip>
    </button>
  );
};
export default Button;
